import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import {
    AngularFirestore,
    AngularFirestoreDocument,
  } from '@angular/fire/compat/firestore';
  import * as auth from 'firebase/auth';
  import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
    ) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return this.afAuth.signInWithEmailAndPassword(email, password);
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return this.afAuth
        .createUserWithEmailAndPassword(email, password);
`        /* .then((result) => {
           /* Call the SendVerificaitonMail() function when new user sign 
           up and returns promise */
           this.SetUserData(result.user);
         })
         .catch((error) => {
           window.alert(error.message);
         }); */
`  
/*        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });*/
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }

     /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user:any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData  = {
      uid: user.uid,
      email: user.email,
      firstName: user.displayName,
      lastName : '',
      password : '',
      status: true
    }
    return userRef.set(userData, {
      merge: true
    })
  }
}

