import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { stat } from 'fs';

// import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor (
    private _router:Router,
    // private _spinner : Ng4LoadingSpinnerService,
    private ngxLoader: NgxUiLoaderService,
    // private _toastr: ToastrService, 

  ) { }

  navigate (path:string):Promise<any> {
    return this._router.navigate([path]);
  }

  showLoader(status:boolean){
    if(status==true){
      this.ngxLoader.start();
    }
    else{
      this.ngxLoader.stop();
    }
  }

//   showSuccesToastr(msg:string){
//     this._toastr.success('', msg);

//   }
//   showErrorToastr(msg:string){
//     this._toastr.error('', msg);

//   }

}
