import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { User } from '../models/auth.models';
import { Chat } from '../../chat/index/chat.model';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    constructor(
        private http: HttpClient,
        private firestore : AngularFirestore
        ) { }

    addUser(form:any) {
        form.id = this.firestore.createId();
        return this.firestore.collection("users").doc(form.id).set(form);
    }

    findUserByUsername(username:string){
        return this.firestore.collection("users", ref => ref.where('username', '==', username)).get();
    }
    findUserByUserId(uid:string){
        return this.firestore.collection("users", ref => ref.where('user_id', '==', uid)).get();
    }

    getAllUsers() {
        return this.firestore.collection("users").get();
    }

    getUserChat(senderId:string, receiverId:string) {
        // return this.firestore.collection("chats").ref.where('senderId', 'in', [senderId, receiverId]).where('receiverId','in',[senderId, receiverId]).orderBy('timestamp','desc').snapshotChanges();
        return this.firestore.collection("chats", ref=> ref.where('senderId','in',[senderId, receiverId]).where('receiverId','in',[senderId, receiverId]).orderBy('timestamp','asc')).snapshotChanges();
    }

    createChat(form:Chat){
        form.id = this.firestore.createId();
        return this.firestore.collection("chats").doc(form.id).set(form);

    }

    getAll() {
        return this.http.get<User[]>(`/api/login`);
    }

    register(user: User) {
        return this.http.post(`/users/register`, user);
    }
}
